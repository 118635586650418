import {useParams, Link, useNavigate} from 'react-router-dom';
import './OneTaskPage.css'
import * as Api from '../../Api'
import React from 'react';
import {TransparentButton} from "../../components/TransparentButton/TransparentButton";
import {SolutionPopup} from "../../components/Popups/SolutionPopup/SolutionPopup"
import {useSolutionContext} from "../../contexts/solution_context";

function OneTaskPage({tasks, path}) {
    const { solutionValue, updateSolution } = useSolutionContext()
    const navigate = useNavigate()
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let {task_id} = useParams();
    const [item, setItem] = React.useState([])
    const [popup, setPopup] = React.useState(false)

    const date = new Date(item.taskDate);
    const deadlineDate = new Date(item.deadline)

    const options = {day: "numeric", month: "long", year: "numeric"};
    const formattedDate = date.toString() !== 'Invalid Date' ?
        date.toLocaleDateString("ru-RU", options) :
        'Дата не определена или определена некорректно';

    let deadlineDateFormatted
    if (deadlineDate.toString() === "Mon Jan 01 0001 02:30:17 GMT+0230 (Москва, стандартное время)") {
        deadlineDateFormatted = "Дата не определена"
    } else if (!deadlineDate) {
        deadlineDateFormatted = "Дата определена некорректно"
    } else {
        deadlineDateFormatted = deadlineDate.toLocaleDateString("ru-RU", options)
    }

    function getItemById() {
        const tokenString = localStorage.getItem("token");
        const token = tokenString ? JSON.parse(tokenString) : null;
        Api.getTaskById(task_id, token)
            .then((res) => {
                setItem(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    React.useEffect(() => {
        getItemById()
    }, [])
    const button_navigate = () => {
        navigate(-1)
    }

    return (
        <div className='OneTaskPage__main-container'>
            <div className="OneTaskPage__top_buttons">
                <TransparentButton onClick={button_navigate}>Назад</TransparentButton>
                {item.solution && <TransparentButton className="solution" onClick={()=>updateSolution(item.solution)}>Просмотреть решение</TransparentButton>}
            </div>
            <div className='OneTaskPage__info-container'>
                <div className="OneTaskPage__column">
                    <div className='OneTaskPage__date-container'>
                        <p className='OneTaskPage__date-title'>Номер задачи:</p>
                        <p className='OneTaskPage__date'>{item.task_id}</p>
                    </div>
                    <div className='OneTaskPage__date-container'>
                        <p className='OneTaskPage__date-title'>Дата создания задачи:</p>
                        <p className='OneTaskPage__date'>{formattedDate}</p>
                    </div>
                    <div className='OneTaskPage__date-container'>
                        <p className='OneTaskPage__date-title'>Дедлайн:</p>
                        <p className='OneTaskPage__date'>{deadlineDateFormatted}</p>
                    </div>
                </div>
                <div className='OneTaskPage__date-container-wrapper OneTaskPage__column'>
                    <div className='OneTaskPage__date-container'>
                        <p className='OneTaskPage__date-title'>Статус согласования:</p>
                        {item.confirm ?
                            <p className='OneTaskPage__date OneTaskPage__date__approved'>Задача согласована</p> :
                            <p className='OneTaskPage__date OneTaskPage__date__unapproved'>He согласована</p>}
                    </div>

                    <div className='OneTaskPage__date-container'>
                        <p className='OneTaskPage__date-title'>Статус задачи: </p>
                        <p className='OneTaskPage__date'>{item.status}</p>
                    </div>
                </div>
            </div>

            <div className='OneTaskPage__wrapper'>
                <div className='OneTaskPage__container'>
                    <h3 className='OneTaskPage__title'>{item.name}</h3>
                    <div className='OneTaskPage__dangerous' dangerouslySetInnerHTML={{__html: item.text_html}}></div>
                </div>
                <div className='OneTaskPage__analyst-and-owner-container'>
                    <div className='OneTaskPage__analyst-container'>
                        {item.analyst == null ?
                            <p>Нет данных об аналитике</p>
                            :
                            <>
                                <p className='OneTaskPage__text OneTaskPage__analyst-text'>Ответственный аналитик:</p>
                                <p className='OneTaskPage__text'>{item.analyst.name}</p>
                                <p className='OneTaskPage__text OneTaskPage__analyst-text'>Телефон:</p>
                                <p className='OneTaskPage__text'>{item.analyst.phone}</p>
                                <p className='OneTaskPage__text OneTaskPage__analyst-text'>Email:</p>
                                <p className='OneTaskPage__text'>{item.analyst.email}</p>
                            </>
                        }
                    </div>

                    <div className='OneTaskPage__analyst-container'>
                        {item.owner == null ?
                            <p>Нет данных об инициаторе</p>
                            :
                            <>
                                <p className='OneTaskPage__text OneTaskPage__analyst-text'>Инициатор:</p>
                                <p className='OneTaskPage__text'>{item.owner.name}</p>
                                <p className='OneTaskPage__text OneTaskPage__analyst-text'>Телефон:</p>
                                <p className='OneTaskPage__text'>{item.owner.phone}</p>
                                <p className='OneTaskPage__text OneTaskPage__analyst-text'>Email:</p>
                                <p className='OneTaskPage__text'>{item.owner.email}</p>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>


    )
}

export default OneTaskPage;