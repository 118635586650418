import React from 'react'
import {Link} from 'react-router-dom'
import './Task.css'

function Task({item, keyWord}) {
    function highlightTextWithHTML(text, keyword) {
        if (!text || !keyword) {
            return { __html: text || '' };
        }
        const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(`(${escapedKeyword})`, 'gi');
        const highlightedText = text.replace(regex, '<span class="highlight">$1</span>');
        return { __html: highlightedText };
    }

    return (
        <li className='task-container'>
            <Link to={`/task/${item.task_id}`} className="task-link">
                <div className='task-status-container'>
                    {item.confirm ? (
                        <p className='task-status-confirm'>Задача согласована</p>
                    ) : (
                        <p className='task-status-no-confirm'>Задача не согласована</p>
                    )}
                    <p className='task-number' dangerouslySetInnerHTML={highlightTextWithHTML(String(item.task_id), keyWord)}></p>
                </div>

                <div className='task-title-container'>
                    <p className='task-title' dangerouslySetInnerHTML={highlightTextWithHTML(item.name, keyWord)}></p>
                </div>

                <div className='task-text-container'>
                    <p className='task-text' dangerouslySetInnerHTML={highlightTextWithHTML(item.text, keyWord)}></p>
                </div>
            </Link>
        </li>
    );
}

export default Task;
