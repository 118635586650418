import React, {useEffect} from 'react'
import OneTask from '../Task/Task'
import Pogination from '../../Pogination/Pogination'
import './TaskTable.css'
import {useParams} from "react-router-dom";

function TaskTable({
                       tasksAfterSearch,
                       limit,
                       changeSetNum,
                       myNum,
                       currentPage,
                       changeActivePage,
                       changeCurrentPage,
                       keyWord
                   }) {
    const {page} = useParams();
    const [toRender, setToRender] = React.useState(tasksAfterSearch)

    const [lastTaskOnPage, setLastTaskOnPage] = React.useState(limit)
    const [firstTaskOnPage, setFirstTaskOnPage] = React.useState(1)

    const [totalPages, setTotalPages] = React.useState([])

    React.useEffect(() => {
        let newArray = Math.trunc(tasksAfterSearch.length / limit) + 2;
        let totalPages = [];
        for (let i = 1; i < newArray; i++) {
            totalPages.push(i);
        }
        if ((tasksAfterSearch.length / limit) % 2 === 0) {
            totalPages.pop();
        }
        setTotalPages(totalPages);
    }, [tasksAfterSearch, limit]);


    React.useEffect(() => {
        setToRender(tasksAfterSearch)

    }, [tasksAfterSearch])

    useEffect(() => {
        changeActivePage(page)
        changeCurrentPage(page - 1)
        changeSetNum(page)
        setLastTaskOnPage(page * limit)
        setFirstTaskOnPage((page - 1) * limit + 1)
    }, [page])

    function getVisibleItems() {
        let start = currentPage * limit
        let end = (currentPage + 1) * limit
        let current_arr = tasksAfterSearch.slice(start, end)
        setToRender(current_arr)
    }

    React.useEffect(() => {
        getVisibleItems();
    }, [currentPage]);

    React.useEffect(() => {
        setLastTaskOnPage(myNum * limit)
        setFirstTaskOnPage((myNum - 1) * limit + 1)
    }, [limit, tasksAfterSearch]);

    function getCurrentTasksNumbers() {
        setLastTaskOnPage(myNum * limit)
        setFirstTaskOnPage((myNum - 1) * limit + 1)
    }

    return (
        <section className='taskTable__section'>
            {toRender.length < limit ?
                <p>Выведены {firstTaskOnPage}-{tasksAfterSearch.length} из {tasksAfterSearch.length}</p>
                :
                (tasksAfterSearch.length <= limit ?
                        <p>Выведены все задачи ({tasksAfterSearch.length})</p>
                        :
                        <p>Выведены {firstTaskOnPage}-{lastTaskOnPage} из {tasksAfterSearch.length}</p>
                )

            }

            <ul className='taskTable__name-container'>
                <li className='taskTable__status' key='11'>Номер задачи</li>
                <li className='taskTable__name' key='12'>Название</li>
                <li className='taskTable__status' key='13'>Статус</li>
                <li className='taskTable__status' key='14'>Инициатор</li>
                <li className='taskTable__status' key='15'>Аналитик</li>
                <li className='taskTable__text' key='16'>Описание</li>
                <li className='taskTable__text' key='18'>Решение</li>
                <li className='taskTable__status-date' key='17'>Дедлайн</li>

            </ul>

            <>
                {toRender.slice(0, limit).map((item) => (
                    <OneTask
                        keyWord={keyWord}
                        key={item.id}
                        item={item}
                    />
                ))}
            </>

            {tasksAfterSearch.length <= limit ?
                <></>
                :
                <ul className='pogination__num-container'>
                    {totalPages.map((number) => (
                        <Pogination
                            number={number}
                            key={number}
                        />
                    ))}
                </ul>
            }

        </section>
    )
}

export default TaskTable;