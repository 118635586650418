export const BASE_URL = `${process.env.REACT_APP_API_URL}`;

export const checkResponse = (res) => {
    console.log(res)
    if (res.ok) {
        console.log(res)
      return res.json();
    } else {
      return Promise.reject(new Error(`Ошибка: ${res.status}`));
    }
};

export const authorization = ( {username, password} ) => {
    return fetch(`${BASE_URL}/jwt/create/`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ 
          username: username, 
          password: password,
        })
    })
    .then(checkResponse)
};

export const getMe = (token) => {
    return fetch(`${BASE_URL}/user/`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(checkResponse)
}

export const getDepartment = (token) => {
    return fetch(`${BASE_URL}/department/`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(checkResponse)
}

export const checkUser = ({ username }) => {
    return fetch(`${BASE_URL}/checkuser/`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ 
          username: username, 
        })
    })
    .then(checkResponse)
};

export const getMytasks = (token) => {
    return fetch(`${BASE_URL}/tasks/`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(checkResponse)
}

export const getTaskById = (task_id, token) => {
    return fetch(`${BASE_URL}/tasks/${task_id}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
    .then(checkResponse)
}

export const approveTask = ( task_id ) => {
    const tokenString = localStorage.getItem("token");
    const token = tokenString ? JSON.parse(tokenString) : null;
    return fetch(`${BASE_URL}/confirmtask/`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ 
            task_id: task_id, 
        })
    })
    .then(checkResponse)
};

export const createTask = ( name, text, selectedDepartmentId ) => {
    const tokenString = localStorage.getItem("token");
    const token = tokenString ? JSON.parse(tokenString) : null;
    return fetch(`${BASE_URL}/createtask/`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ 
            name: name, 
            text: text,
            department: selectedDepartmentId,
        })
    })
    .then(checkResponse)
};

export const deleteTask = ( task_id ) => {
    const tokenString = localStorage.getItem("token");
    const token = tokenString ? JSON.parse(tokenString) : null;
    return fetch(`${BASE_URL}/canceltask/`, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ 
            task_id: task_id, 
        })
    })
    .then(checkResponse)
};
export const getSolution = (id) => {
    const tokenString = localStorage.getItem("token");
    const token = tokenString ? JSON.parse(tokenString) : null;
    return fetch(`${BASE_URL}/solutions/${id}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
    })
        .then(checkResponse).catch(error => {
            console.error("Error fetching solution:", error)
        })
}
