import React, { createContext, useState, useContext } from 'react';

const SolutionContext = createContext();

export const SolutionProvider = ({ children }) => {
    const [solutionValue, setSolutionValue] = useState('');

    const updateSolution = (newSolution) => {
        setSolutionValue(newSolution);
    };

    return (
        <SolutionContext.Provider value={{ solutionValue, updateSolution }}>
            {children}
        </SolutionContext.Provider>
    );
};

export const useSolutionContext = () => {
    return useContext(SolutionContext);
};
