import style from "./SolutionPopup.module.css"
import * as Api from '../../../Api'
import React, {useEffect, useState} from "react";
import closeIcon from "../../../images/close.svg"
import {useSolutionContext} from "../../../contexts/solution_context";

export function SolutionPopup() {
    const {solutionValue, updateSolution} = useSolutionContext()
    const closeWindow = () => {
        updateSolution('')
    }
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeWindow();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const [data, setData] = useState();
    const port = process.env.REACT_APP_PORT || '';

    useEffect(() => {
        const fetchData = async () => {
            const res = await Api.getSolution(solutionValue);
            setData(res);
        };

        fetchData();
    }, [solutionValue]);
    const constructFileUrl = (fileUrl) => {
        if (port && !window.location.href.includes("help.veleres.ru")) {
            return fileUrl.replace(/^(https?:\/\/[^\/:]+)(:\d+)?(\/.*)?$/, `$1:${port}$3`);
        }
        return fileUrl;
    };
    return (
        <>
            <div onClick={closeWindow} className={style.background}></div>
            {data &&
                <div className={style.window}>
                    <button onClick={closeWindow} className={style.close}><img src={closeIcon} alt="Close"/></button>
                    <p className={style.name}>{data.name}</p>
                    <div dangerouslySetInnerHTML={{__html: data.solution_html}}></div>
                    <div className={style.links}>
                        <p>Файлы для скачивания:</p>
                        {data.files.map((el, index) => (
                            <a key={index} href={constructFileUrl(el.file)} download>{el.name}</a>
                        ))}
                    </div>
                </div>}
        </>
    );
}
